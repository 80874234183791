import React from "react"

export const withForgot = Component => ({ name = "Forgot", page, ...props }) => {
  const title = page?.title
  const subTitle = page?.subTitle
  const image = page?.image?.asset?.fluid

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Login > Forgot your password",
    },
  ]

  Component.displayName = name
  return <Component {...props} title={title} subTitle={subTitle} image={image} paths={paths} />
}
