import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import { withForgotForm } from "./withForgotForm"
import { StyledButton } from "../../Styled/Button"
import FormInput from "../../Form/FormInput"

const Input = styled(FormInput)`
  ${tw`mb-1-2 px-0`}
`

const Submit = styled(StyledButton)`
  ${tw`w-full mt-1-6`}
`

const Error = tw.p`
  text-orange text-12 h-1-2
`

const StyledLink = styled(Link)`
  ${tw`text-16 text-center mt-3-2 block underline`}
`

export const ForgotForm = withForgotForm(({ data, handleChange, handleSubmit, loading, errors }) => (
  <form onSubmit={handleSubmit}>
    <Input name={`email`} type={`email`} placeholder={`Email`} placeholderColor={`dark`} value={data.email} handleChange={handleChange} required />
    {errors?.length > 0 && errors?.filter(error => error?.field?.length && error?.field[1] === "email").map(error => <Error>{error?.message}</Error>)}
    <Submit type={`submit`} height={`56`} textSize={`16`} colour={`green`} disabled={loading}>
      Submit
    </Submit>
    <StyledLink to={`/account/login`}>Back to login</StyledLink>
  </form>
))

ForgotForm.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.any,
}
